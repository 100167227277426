// Buttons and Forms (shared settings)
$button-form--height: 48px; // height of buttons and forms
$button-form-sm--height: 36px; // height of buttons and forms (small)
$button-form--border-width: 1px; // border width (if you wish not to display any border, just set colors to null instead of changing this value)
$button-form--border-style: solid; // border style
$button-form--group-padding: 12px; // padding separating input from button in a form group (.form-group). Prefer null over 0

// Buttons: text color
$button--color: (
  button-light: get-color(dark, 2),
  button-dark: get-color(light, 1),
  button-primary: get-color(light, 1),
  button-secondary: get-color(light, 1),
  button-disabled: get-color(dark, 3),
);

// Buttons: background color
$button--bg: (
  button-light: get-color(light, 1),
  button-light-hover: darken(get-color(light, 1), 5%),
  button-dark: get-color(dark, 2),
  button-dark-hover: lighten(get-color(dark, 2), 2%),
  button-primary: get-color(primary, 1),
  button-primary-hover: lighten(get-color(primary, 1), 2%),
  button-secondary: get-color(secondary, 1),
  button-secondary-hover: lighten(get-color(secondary, 1), 2%),
  button-disabled: get-color(light, 3),
);

// Buttons: border color
$button--border: (
  button-light: transparent,
  // button-light is default (.button), so if it's transparent you can set all other values to null
  button-light-hover: null,
  button-dark: null,
  button-dark-hover: null,
  button-primary: null,
  button-primary-hover: null,
  button-secondary: null,
  button-secondary-hover: null,
  button-disabled: null,
);

// Buttons: font size
$button--size: (
  button: "eta",
);

// Buttons: font weight
$button--weight: (
  button: 600,
);

// More buttons settings
$button--padding-h: 32px; // button, left and right padding
$button-sm--padding-h: 24px; // button (small), left and right padding
$button-w-form--padding-h: 24px; // button, left and right padding in a form group (.form-group) - Overrides button default padding
$button-sm-w-form--padding-h: null; // button (small), left and right padding in a form group (.form-group) - Overrides button default padding
$button--radius: 2px; // button radius (never use null)
$button--transform: null; // button text-transform property (null is allowed)
$button--max-size-mobile: 280px; // button max width on mobile (.button-wide-mobile)
$button-group-padding: 16px; // padding between sibling buttons (nested into .button-group)
$button-loading--thickness: 2px; // button loading spinner, stroke width (.button.is-loading)

// Forms: color
$form--color: (
  input: get-color(dark, 1),
  input-placeholder: get-color(light, 1),
  label: get-color(light, 3),
  check-radio: get-color(light, 2),
  // ↓ Inverted colors
  input-inverse: get-color(dark, 3),
  input-placeholder-inverse: null,
  label-inverse: get-color(dark, 3),
  check-radio-inverse: get-color(dark, 3),
);

// Forms: background color
$form--bg: (
  input: get-color(dark, 1),
  input-focus: null,
  input-disabled: get-color(dark, 3),
  check-radio-checked: get-color(primary, 1),
  switch: get-color(dark, 3),
  switch-checked: get-color(primary, 1),
  range-track: get-color(light, 2),
  range-thumb: get-color(primary, 1),
  // ↓ Inverted colors
  input-inverse: null,
  input-focus-inverse: null,
  input-disabled-inverse: darken(get-color(light, 1), 4%),
  check-radio-checked-inverse: null,
  switch-inverse: null,
  switch-checked-inverse: null,
  range-track-inverse: null,
  range-thumb-inverse: null,
);

// Forms: border color
$form--border: (
  input: get-color(dark, 1),
  input-hover: get-color(light, 3),
  input-focus: get-color(primary, 1),
  input-disabled: get-color(dark, 2),
  // ↓ Inverted colors
  input-inverse: darken(get-color(light, 1), 4%),
  input-hover-inverse: null,
  input-focus-inverse: get-color(primary, 1),
  input-disabled-inverse: null,
);

// Forms: background color
$form--icon: (
  select-arrow: get-color(light, 1),
  range: get-color(light, 1),
  // ↓ Inverted colors
  select-arrow-inverse: get-color(dark, 3),
  range-inverse: get-color(light, 1),
);

// Forms: font size
$form--size: (
  input: "eta",
  label: "theta",
  check-radio: "eta",
  hint: "theta",
);

// Forms: font weight
$form--weight: (
  input: null,
  label: 500,
  check-radio: null,
);

// More forms settings
$form--padding-h: 16px; // form, inner left and right padding
$form-sm--padding-h: 12px; // form (small), inner left and right padding
$form--radius: 0; // form radius (never use null)
$form-hint--margin: 8px; // .form-hint top margin
$range-thumb-size: 36px; // .form-slider, thumb size

// Don't change lines below!
// Push $button--* and $form--* values
$color: map-push($color, $button--color);
$color: map-push($color, $form--color);
$bg--color: map-push($bg--color, $button--bg);
$bg--color: map-push($bg--color, $form--bg);
$border--color: map-push($border--color, $button--border);
$border--color: map-push($border--color, $form--border);
$icon--color: map-push($icon--color, $form--icon);
$font--size: map-push($font--size, $button--size);
$font--size: map-push($font--size, $form--size);
$font--weight: map-push($font--weight, $button--weight);
$font--weight: map-push($font--weight, $form--weight);
