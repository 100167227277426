.dropbtn {
  background-color: white;
  color: black;
  padding: 0.3em 0em;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 0.2em;
  padding: 4px;
  z-index: 2;
}

.dropbtn:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  color: black;
  display: none;
  position: absolute;
  background-color: white;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: white;
  color: #fe830d;
  cursor: pointer;
  border-top: 2px solid #fe830d;
  border-bottom: 2px solid #fe830d;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown:hover .dropdown-content {
  display: block;
  color: black;
}

.dropdown:hover .dropbtn {
  background-color: rgba(255, 255, 255, 0.3);
}
